import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueObserveVisibility from 'vue-observe-visibility'
import Notifications from 'vue-notification'

Vue.use(VueObserveVisibility)
Vue.use(Notifications)


Vue.prototype.$axios = axios;
Vue.config.productionTip = false

const getConfig = async () => {
  const url = location.origin + '/config.json';
  let config = null;
  try {
    config = await axios.get(url);
  } catch (error) {
    throw new Error('Fail get config', error);
  }
  return config.data;
}
 
const useApiKey = async (config) => {
  axios.defaults.headers.common['Authorization'] = 'Api-Key ' + config.API_KEY
}

const initApp = async () => {
  const config = await getConfig()
  await useApiKey(config)
  Vue.prototype.$config = config

  await new Vue({
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

initApp();