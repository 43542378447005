<template>
  <div class="header">
    <img src="../../assets/LogoHeader.png" alt="">

    <div class="header__actions">
      <v-btn 
        class="header__btn mr-2" 
        elevation="0"
        @click="openUrl('https://zarbo.tech/')"
        depressed
      >
        <v-icon 
          size="20" 
          class="mr-2"
        >
          $vuetify.icons.arrowNav
        </v-icon>
        <span class="header__btn-max-site">Вернуться на сайт</span>
        <span class="header__btn-min-site">на сайт</span>
        
      </v-btn>
      <v-btn 
        class="header__btn" 
        elevation="0" 
        @click="openUrl('https://app.zarbo.tech/')"
        depressed
      >
        <span class="header__btn-max">Войти в приложение Zarbo </span>
        <span class="header__btn-min">Войти</span>
        
        <v-icon 
          size="16" 
          class="ml-2"
        >
          $vuetify.icons.user
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      openUrl (url) {
        window.open(url, '_self')
      }
    }
  }
</script>

<style>
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 16px;
  margin-top: 24px;
  background: #DDDCFF;
  border-radius: 10px;
}
.header__btn{
  background: #FFFFFF;
  border-radius: 10px;
  height: 40px;
}
.header__btn .v-btn__content{
  color: #4E4087;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
}
  
.header__actions .v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
  padding: 0 28px;
}
.header__actions .theme--light.v-btn:hover::before {
  opacity: 0;
}
.header__actions .theme--light.v-btn:focus::before {
  opacity: 0;
}
.header__btn-min{
  display: none;
}
.header__btn-min-site{
  display: none;
}
</style>