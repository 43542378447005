<template>
  <div>
    <HeaderGalleryVue @searchResult="searchResult = $event" :resetSearch="resetSearch"/>
    <div class="gallery">
      <MenuVue :collections="collectionsMenu" @selectedCollection="selectedCollection = $event" @resetSearch="resetSearch= $event"/>
      <ProductsVue :products="products" :loadingProducts="loadingProducts" @isVisible="isVisible = $event" :selectedCollection="selectedCollection" :searchResult="searchResult"/>
    </div>
  </div>
</template>

<script>
import MenuVue from './MenuVue';
import ProductsVue from './ProductsVue';
import HeaderGalleryVue from './HeaderGalleryVue';

  export default {
    components: {
      MenuVue,
      ProductsVue,
      HeaderGalleryVue
    },
    data() {
      return {
        collections: null,
        products: [],
        selectedCollection: null,
        loadingProducts: false,
        isVisible: false,
        countProducts: 0,
        nextProducts: null,
        collectionsMenu: [],
        searchResult: '',
        resetSearch: 0
      }
    },
    watch: {
      selectedCollection () {
        this.loadingProducts = true;
        this.products = []
        this.getCollectionProducts()
      },
      isVisible (newVal) {
        if (newVal && this.nextProducts) {
          this.getCollectionProducts(this.nextProducts)
          this.nextProducts = null
        }
      }
    },
    async mounted () {
       await this.getCollections()
       await this.getCollectionProducts() 
       const selectedCollection = JSON.parse(localStorage.getItem('selectedCollectionGallery'));
       if (selectedCollection) {
        this.selectedCollection = selectedCollection
       }
    },

    methods: {
      async getCollections () {
        const url = this.$config.BASE_URL + '/api/v1/collections/'
        try {
          const res = await this.$axios.get(url)
          this.collections = res.data
        } catch (error) {
          this.collections = []
          console.log(error);
        }
      },
      async getCollectionProducts(nextUrl) {
        this.loadingProducts = true;
        let collectionKeys;
        if (this.selectedCollection) {
          collectionKeys = this.selectedCollection.key
        } else {
          collectionKeys = this.collections.map((item) => item.key).join()
        }
        if (!collectionKeys) {
          return;
        }
        const tagName = this.$config.TAG
        let url
        if (nextUrl) {
          url = nextUrl
        } else {
          url = this.$config.BASE_URL + '/api/v1/products/?collections=' + collectionKeys + '&tags=' + tagName + '&limit=15&offset=0';
        }
        try {
          const resp = await this.$axios.get(url);
          this.nextProducts = resp.data.next
          let products = resp.data.results.map((prod) => {
            // добавляем флаг загрузки виджетов продукта
            return {
              ...prod,
            };
          });
          // console.log('resp.data.results', resp.data.results)
          this.collectionsMenu = this.collections = this.collections.filter(item => item.count !== 0);

          let promises = [];
          products.forEach((prod) => {
            // запрос виджетов для каждого продукта
            let urlWidget = this.$config.BASE_URL + '/api/v1/widgets/?product=' + prod.id;
            promises.push(this.$axios.get(urlWidget));
          });

          Promise.all(
            promises.map((promise, promiseIndex) => {
              return promise.then(
                (value) => {
                  // resolve(для каждого отдельного запроса)
                  this.$set(products[promiseIndex], 'widgets', value.data);
                },
                () => {
                  // reject
                  this.$set(products[promiseIndex], 'widgets', {
                    count: 0,
                    results: [],
                  });
                }
              );
            })
          );
          if (nextUrl) {
            this.products = this.products.concat(products)
          } else {
            this.products = products
          }
          this.loadingProducts = false;
        } catch (error) {
          console.error('fail get products', error);
          this.loadingProducts = false;
          this.products = [];
        }
      },
    },
  }
</script>