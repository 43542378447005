<template>
  <div class="catalog__header">
    <h1 class="catalog__title">{{isFullScreen ? "Ваш интернет магазин" : 'Примеры' }}</h1>
    <div class="catalog__card-wrap">
      <div class="catalog__search" v-show="isVisibleSearch">
        <v-text-field
          class="catalog__search-input"
          label="Поиск примеров"
          solo
          hide-details
          background-color="#F1EEF8"
          v-model="searchProduct"
          @keypress.enter="searchResult = searchProduct"
        >
          <template slot="append">
            <v-icon color="#232451" @click="searchResult = searchProduct">
              mdi-magnify
            </v-icon>
          </template>
          <template slot="append">
            <v-icon 
              color="#232451"
              @click="isVisibleSearch = !isVisibleSearch"
              class="mini-search-close"
              size="16"
            >
              $vuetify.icons.closeRounded
            </v-icon>
          </template>
        </v-text-field>
      </div>

      <v-btn class="mini-search" elevation="0" v-show="!isVisibleSearch" @click="isVisibleSearch = !isVisibleSearch">
        <v-icon color="#232451">
          mdi-magnify
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['resetSearch'],
    data() {
      return {
        searchProduct: '',
        searchResult: '',
        isVisibleSearch: true,
        width: 0
      }
    },
    computed: {
      isFullScreen () {
        const isFullScreen = this.$config.FULL_SCREEN
        return isFullScreen
      },
      
    },
    watch: {
      resetSearch () {
        this.searchProduct = ''
      },
      searchProduct (newVal) {
        if (newVal === '') {
          this.searchResult = ''
        }
      },
      width (newVal) {
        if (newVal <= 576) {
          this.isVisibleSearch = false
        }
      },
      searchResult (newVal) {
        this.$emit('searchResult', newVal)
      }
    },
    mounted () {
      this.width = window.innerWidth
    },
  }
</script>