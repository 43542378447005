<template>
  <v-app id="app" :class="isFullScreen && width ? 'full-screen' : ''">
    <div class="container">
      <HeaderVue v-if="!isFullScreen" />
      <GallleryVue/>
    </div>
    <v-footer padless color="#DDDCFF" v-if="!isFullScreen">
      <div class="container">
        <div class="footer__wrap">
          <span class="footer__rights">© Zarbo. 2022 Все права защищены.</span>
          <div class="footer__email" @click="copyEmail">
            <v-icon color="#232451" class="mr-3">$vuetify.icons.email</v-icon>
            <span>info@zarbo.tech</span>
          </div>
        </div>
      </div>  
    </v-footer>
    <notifications group="foo" />
  </v-app>
</template>

<script>
import GallleryVue from './components/GalleryPage/GalleryVue'
import HeaderVue from './components/Commons/HeaderVue'
export default {
  name: 'App',
  components: {
    GallleryVue,
    HeaderVue
  },
  computed: {
    width () {
      const screenWidth = window.innerWidth
      return screenWidth >= 1545
    },
    isFullScreen () {
      const isFullScreen = this.$config.FULL_SCREEN
      return isFullScreen
    }
  },
  methods: {
    copyEmail() {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText('info@zarbo.tech')
          .then(() => {
            this.$notify({
              group: 'foo',
              text: 'Адрес info@zarbo.tech успешно скопирован',
              type: 'success',
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log('нет navigator.clipboard');
      }
    },
  }
}
</script>

<style>
*{
  box-sizing: border-box;
  font-family: 'Montserrat';
}
.full-screen .container{
  max-width: 1545px;
}
.full-screen .catalog__collection {
  width: 23%;
}
.full-screen .catalog__search{
  width:100%;
  padding-left: 66px;
}
.full-screen .catalog__search-input{
  max-width: 950px;
}
.full-screen .catalog__cards{
  padding-left: 100px;
  width: 77%;
}
.full-screen .catalog__subscription{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.full-screen .popup{
  padding: 60px 96px 60px 80px;
}
.full-screen .catalog__image{
  min-height: 351px;
}
.full-screen .catalog__wrap {
  gap: 25px;
}
.full-screen .popup__buy {
  max-width: 209px;
}
.full-screen .third-markup .popup__buy {
  max-width: 230px;
}
.full-screen .popup__menu {
  width: 27%;
  justify-content: space-between;
  padding: 60px 0 80px 60px;
}
.full-screen .second-markup .popup__menu {
  padding: 60px 60px 80px 0;
}
.full-screen .second-markup .popup__seller {
  bottom: 95px;
}
.full-screen .popup__widget {
  width: 73%;
}
.full-screen .popup__widget__iframe {
  height: 600px;
}
.container{
  max-width: 1240px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}
.v-application--wrap{
  justify-content: space-between;
}
.mini-search{
  display: none;
}
.mini-search-close{
  display: none!important;
}
.custom-icon {
  fill: currentColor;
}
.catalog__header{
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
.catalog{
  color: #232451;
}
.catalog__title{
  display: inline-block;
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
  position: relative;
  z-index: 1;
  margin-bottom: 34px;
  white-space: nowrap;
  margin-right: 30px;
}

.catalog__title::after{
  z-index: -1;
  content: "";
  display: block;
  background: linear-gradient(265.28deg, #EC5B64 0%, #E04CA1 48.05%, #B636EA 100.05%);
  border-radius: 32px 32px 32px 2px;
  position: absolute;
  bottom: 7px;
  width: 100%;
  height: 9px;
}
.catalog__search .v-input__control{
  min-height: 54px;
}
.catalog__search-input{
  width: 100%;
  max-width: 405px;
  background: #F1EEF8;
  border-radius: 10px;
}
.catalog__search-input .v-input__append-inner{
  display: flex;
  align-items: center;
}
.catalog__search-input input, label{
  font-size: 16px;
  line-height: 22px;
  color: #7F788C!important;
}
.catalog__search-input .v-input__slot{
  box-shadow: none!important;
  padding-left: 25px!important;
}

.catalog__main{
  display: flex;
}
.catalog__collection{
  width: 28%;
}
.catalog__collection-title{
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 20px;
  line-height: 32px;
}
.catalog__collection-item{
  font-weight: 700;
  font-size: 18px;
  padding: 16px 20px;
  background: #F1EEF8;
  border-radius: 10px;
  margin-bottom: 2px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #232451;
  word-break: break-all;
}

.catalog__collection-item:hover{
  background: #DFD8EE;
}
.active-item-menu{
  background: #DFD8EE;
}

.catalog__cards{
  width: 72%;
  padding-left: 48px;
}
.catalog__wrap{
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 20px));
  justify-content: space-between;
  gap: 30px;
  width: 100%;
}
.catalog__cards-title{
  font-weight: 600;
  font-size: 20px;
  color: #6E6973;
  margin-bottom: 21px;
  display: flex;
}
.catalog__cards-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 9px;
  border-width: 1px;
  border-style: solid;
  border-color: #F1EEF8;
  cursor: pointer;
}
.catalog__cards-item:hover {
  box-shadow: rgba(110, 99, 120, 0.15) 0px 3px 24px;
}
.catalog__cards-item:hover .catalog__subscription{
  background: #F1EEF8;
}

.catalog__image{
  min-height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.catalog__image img {
  height: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
}
.catalog__subscription{
  padding: 18px 20px;
  background: #F8F6FA;
  width: 100%;
  color: #232451;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  height: 100%;
}
.catalog__card-wrap{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  z-index: 10;
  width: 100%;
}

.gallery{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 50px;
  color: #232347;
  min-height: 80vh;
}
.progress__block{
  display: flex;
  justify-content: center;
  margin: 0 50px;
}
.footer__wrap{
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  align-items: center;
  flex-wrap: wrap;
}
.footer__rights{
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #8786A8;
}
.footer__email{
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #232451;
  cursor: pointer;
}
.catalog__search .v-text-field.v-text-field--solo .v-input__control {
  min-height: 54px;
}

@media (max-width: 1024px) {
  .catalog__title{
    font-size: 36px;
    margin-bottom: 25px;
    line-height: 44px;
  }
  .catalog__title::after{
    bottom: 5px;
  }
  .catalog__collection-title{
    margin-bottom: 15px;
    font-size: 22px;
  }
  .catalog__collection-item{
    font-size: 16px;
  }
  .catalog__cards{
    padding-left: 40px;
  }
  .catalog__card-wrap{
    margin-bottom: 19px;
  }
  .catalog__cards-title{
    font-size: 16px;
    margin-bottom: 17px;
    line-height: 26px;
  }
  .catalog__wrap{
    gap: 20px;
  }
  .catalog__image{
    min-height: 190px;
  }
  .catalog__subscription{
    font-size: 13px;
    padding: 12px 11px;
    line-height: 18px;
  }
  .popup__widget{
    width: 60%;
  }
  .popup__menu{
    width: 40%;
  }
  .header__actions .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 24px;
  }
}
@media (max-width: 768px) {
  .catalog__collection{
    padding-top: 0;
  }
  .catalog__title{
    font-size: 32px;
    margin-bottom: 29px;
    line-height: 38px;
  }
  .catalog__collection-title{
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 26px;
  }
  .catalog__collection-item{
    font-size: 14px;
    padding: 13px 15px 13px 20px;
    line-height: 18px;
  }
  .catalog__card-wrap{
    margin-bottom: 13px;
  }
  .catalog__cards-title{
    margin-bottom: 16px;
    font-size: 14px;
  }
  .catalog__wrap{
    grid-template-columns: repeat(2, calc(50% - 10px));
  }
  .catalog__image{
    min-height: 215px;
  }
  .popup__title{
    font-size: 26px;
    margin-bottom: 11px;
  }
  .popup__widget{
    width: 50%;
  }
  .popup__menu{
    width: 50%;
  }
  .header__btn-max{
    display: none;
  }
  .header__btn-min {
    display: block;
  }
  .header__actions .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 12px;
  }
  .popup__characteristics{
    height: 50px;
  }
}
@media (max-width: 610px) {
  .popup__characteristics {
    font-size: 14px;
    padding: 10px 15px;
  }
  .catalog__search-input {
    width: 370px;
  }
}
@media (max-width: 576px) {
  .container{
    padding: 0 16px;
  }
  .catalog__title{
    font-size: 19px;
    line-height: 32px;
  }
  .catalog__title::after{
    height: 6px;
  }
  .gallery{
    flex-direction: column;
    position: relative;
  }
  .catalog__collection{
    width: 100%;
    margin-bottom: 35px;
  }
  .catalog__collection-item{
    padding: 13px 15px 13px 20px;
  }
  .catalog__card-wrap{
    position: absolute;
    top: 45px;
    right: 16px;
    margin: 0;
  }
  .catalog__search{
    width: 300px;
  }
  .catalog__cards{
    width: 100%;
    padding: 0;
  }
  .catalog__cards-title{
    line-height: 24px;
    margin-bottom: 25px;
  }
  .catalog__wrap{
    gap: 15px;
    grid-template-columns: repeat(2, calc(50% - 5px));
  }
  .catalog__image{
    min-height: 160px;
  }
  .catalog__subscription{
    padding: 10px;
  }
  .popup__content{
    flex-direction: column;
  }
  .second-markup .popup__content{
    flex-direction: column-reverse;
  }
  .popup__widget{
    width: 100%;
  }
  .popup__menu{
    width: 100%;
  }
  .popup {
    padding: 60px 30px 50px 30px;
  }
  .popup__widget{
    min-height: 375px;
    margin-bottom: 25px;
  }
  .popup__title-third{
    font-size: 26px;
    margin-bottom: 25px;
  }
  .popup__menu{
    padding: 0;
  }
  .popup__buy{
    max-width: 210px;
  }
  .popup__price{
    margin-bottom: 27px;
  }
  .popup__price-now{
    font-size: 28px;
  }
  .popup__price-old{
    font-size: 20px;
  }
  .second-markup .popup__actions{
    margin-bottom: 35px;
  }
  .second-markup .popup__seller{
    position: inherit;
  }
  .second-markup .popup__widget{
    margin-bottom: 0;
  }
  .header__actions .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 12px;
  }
  .header__btn-max-site{
    display: none;
  }
  .header__btn-min-site{
    display: block;
  }
  .header__btn-min {
    display: none;
  }
  .header__btn .v-icon{
    margin-left: 0!important;
  }
  .header__btn:last-child{
    padding: 12px!important;
    min-width: 0!important;
  }
  .mini-search{
    display: block;
    background: #F1EEF8;
    height: 42px!important;
    padding: 0!important;
    min-width: 42px!important;
    border-radius: 10px;
  }
  .mini-search-close{
    display: block!important;
  }
  .header img {
    width: 120px;
  }
  .catalog__search .v-text-field.v-text-field--solo .v-input__control {
    min-height: 42px;
  }
  .catalog__card-wrap{
    width: 100%;
  }
  .catalog__search{
    width: 95%;
  }
  .catalog__search-input{
    width: 100%;
    max-width: 100%;
  }
  
}
@media (max-width: 320px) {
  
  .catalog__title{
    font-size: 24px;
    margin-bottom: 15px;
  }
  .catalog__collection-title{
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .catalog__card-wrap{
    right: 16px;
  }
  .catalog__collection-item{
    font-size: 14px;
    line-height: 18px;
  }
  .catalog__collection{
    margin-bottom: 25px;
  }
  .catalog__cards-title{
    font-size: 13px;
    line-height: 22px;
  }
  .catalog__wrap{
    gap: 10px;
    grid-template-columns: repeat(2, calc(50% - 5px));
  }
  .popup__title-third{
    font-size: 22px;
    margin-bottom: 15px;
  }
  .popup__title{
    font-size: 22px;
  }
  .popup__widget{
    min-height: 380px;
    margin-bottom: 25px;
  }
  .popup {
    padding: 45px 15px 47px 15px;
  }
  .popup__price-now {
    font-size: 28px;
  }
  .popup__price-old {
    font-size: 20px;
  }
  .popup__characteristics{
    padding: 7px 20px;
  }
  .second-markup{
    padding: 25px 15px 15px 15px;
  }
  .second-markup .popup__menu{
    padding: 0;
    position: relative;
    padding-bottom: 60px;
  }
  .second-markup .popup__seller {
    position: absolute;
    bottom: 10px;
  }
  .popup__close {
    right: 15px;
    top: 15px;
  }
  .third-markup {
    padding: 35px 15px;
  }
  .third-markup .popup__widget{
    margin-bottom: 5px;
  }
  .popup__feedback {
    margin-bottom: 5px;
  }
  .third-markup .popup__feedback{
    margin-bottom: 5px;
  }
  .third-markup .popup__buy{
    min-width: 100%;
  }
}
</style>
