
<template>
  <div class="catalog__collection">
    
    <div class="catalog__collection-title">Категории</div>
    <div v-for="(collection, index) in collections" :key="index" class="catalog__collection-item" :class="isActiveItem(collection)" @click="selectCollection(collection)">
      {{collection.name}}
      <v-icon color="#232451">
        mdi-chevron-right
      </v-icon>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['collections'],
    data() {
      return {
        selectedCollection: null,
        resetSearch: 0
      }
    },
    mounted () {
      const selectedCollection = JSON.parse(localStorage.getItem('selectedCollectionGallery'));
       if (selectedCollection) {
        this.selectedCollection = selectedCollection
       }
    },
    methods: {
      isActiveItem (item) {
        if (this.selectedCollection?.key === item.key) {
          return 'active-item-menu'
        }
        return ''
      },
      selectCollection (collection) {
        this.resetSearch++
        this.$emit('resetSearch', this.resetSearch)
        if (this.selectedCollection?.key === collection.key) {
          this.selectedCollection = null
          this.$emit('selectedCollection', null)
          localStorage.setItem('selectedCollectionGallery', null)
          return
        }
        this.selectedCollection = collection
        this.$emit('selectedCollection', collection)
        localStorage.setItem('selectedCollectionGallery', JSON.stringify(collection));
        return
      }
    }
    
  }
</script>

<style>

</style>