<template>
  <div class="popup" :class="getRandomClass(this.randomNumbers.markup)">
    <div class="d-flex justify-space-between">
      <div class="popup__title-third">{{product.name}}</div>
      <div class="popup__close"><v-icon size="20" @click="closePopup" color="#232451">$vuetify.icons.closeRounded</v-icon></div>
    </div>
    <div class="popup__content">
      <div class="popup__widget">
        <div v-if="urlWidget">
          <iframe  class="popup__widget__iframe" :src="urlWidget" allowfullscreen allow="camera;autoplay;xr-spatial-tracking"></iframe>
        </div>
        <div v-else class="popup__widget__iframe">
          <span>Нет виджета у продукта</span>
        </div>
      </div>
      <div class="popup__menu">
        <div class="popup__static">
          <div class="popup__title">{{product.name}}</div>
          <div class="popup__seller">Продавец: ZARBO</div>
          <div class="popup__feedback">
            <div class="popup__stars">
              <v-icon size="13" v-for="(star, index) in 5" :key="index">
                $vuetify.icons.star
              </v-icon>
            </div>
            <div class="popup__recalls" :style="getRandomColor(this.randomNumbers.color, 'text')">{{getReviewWord(this.randomNumbers.recalls)}}</div>
          </div>
          <div class="mb-5 d-flex">
            <div class="popup__characteristics" :style="getRandomRounded(this.randomNumbers.rounded)">Характеристики <v-icon>mdi-chevron-down</v-icon></div>
            <div class="popup__qr" :style="getRandomRounded(this.randomNumbers.rounded)" @click="openQrcode" v-if="isFullScreen">QR</div>
          </div>
          <div class="popup__price">
            <div class="popup__price-now" :style="getRandomColor(this.randomNumbers.color, 'text')">{{randomPrice.toString().slice(0, 2) + " " + randomPrice.toString().slice(2)}} ₽</div>
            <div class="popup__price-old">{{(randomPrice + 4000).toString().slice(0, 2) + " " + (randomPrice + 4000).toString().slice(2)}} ₽</div>
          </div>
        </div>
        <div class="popup__actions">
          <div class="popup__buy" :style="getRandomRounded(this.randomNumbers.rounded) + getRandomColor(this.randomNumbers.color)">купить</div>
          <div class="popup__like" :style="getRandomRounded(this.randomNumbers.rounded) + getRandomColor(this.randomNumbers.color)">
            <v-icon color="white">mdi-heart-outline</v-icon>
          </div>
        </div>
      </div> 
    </div>
    <div class="popup__qr__block" v-show="isPopupQr">
      <div class="popup__qr__close" @click="isPopupQr =false"><v-icon color="#FFFFFF">$vuetify.icons.closeRounded</v-icon></div>
      <div class="popup__qr__wrap">
        <div class="qr-code" id="qr-code">

        </div>
        <div class="popup__qr__bottom">
          <div class="popup__qr__text">Наведите камеру телефона</div>
          <div class="popup__qr__icon"><img src="../../assets/qr.svg" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'easyqrcodejs'

  export default {
    props: ['product', 'randomNumbers'],
    data() {
      return {
        isPopupQr: false
      }
    },
    computed: {
      urlWidget () {
        let url = this.$config.EMBED_URL + '/' + this.product.uuid + '/'
        const widgetId = this.product?.widgets?.results[0]?.id
        return widgetId ? url + widgetId : null
      },
      randomPrice () {
        const min = 10000
        const max = Math.floor(50000)
        return Math.floor(Math.random() * (max - min + 1)) + min
      },
      isFullScreen () {
        const isFullScreen = this.$config.FULL_SCREEN
        return isFullScreen
      }     
    },
    methods: {
      openQrcode () {
        let qrCode = document.getElementById('qr-code')

        if (qrCode.firstChild) {
          qrCode.removeChild(qrCode.firstChild);
        }
        new QRCode(qrCode, {
          text: this.urlWidget,
          width: 256,
          height: 256,
        })
        qrCode.title = this.urlWidget
        this.isPopupQr = true
      },
      getReviewWord(n) {
        const lastDigit = n % 10;
        const lastTwoDigits = n % 100;
          switch (true) {
            case lastTwoDigits in [11, 12, 13, 14]:
              return n + " отзывов";
            case lastDigit === 1:
              return n + " отзыв";
            case lastDigit in [2, 3, 4]:
              return n + " отзыва";
            case lastDigit === 0 || lastDigit >= 5:
              return n + " отзывов";
            default:
              return n + " отзывов";
          }
        },
      getRandomClass (int) {
        switch (int) {
          case 1:
            return 'first-markup'
          case 2:
            return 'second-markup'
          case 3:
            return 'third-markup'
          default:
          return ''
        }
      }, 
      getRandomRounded (int) {
        switch (int) {
          case 1:
            return 'border-radius: 25px;'
          case 2:
            return 'border-radius: 10px;'
          case 3:
            return 'border-radius: 0px;'
          default:
          return ''
        }
      },
      getRandomColor (int, type) {
        switch (int) {
          case 1:
            if (type === 'text') {
              return 'color: #A045C0;'
            }
            return 'background: #A045C0;'
          case 2:
            if (type === 'text') {
              return 'color: #4E9FFF;'
            }
            return 'background: #4E9FFF;'
          case 3:
            if (type === 'text') {
              return 'color: #FF4848;'
            }
            return 'background: #FF4848;'
          case 4:
            if (type === 'text') {
              return 'color: #45C0B1;'
            }
            return 'background: #45C0B1;'
          case 5:
            if (type === 'text') {
              return 'color: #FF842B;'
            }
            return 'background: #FF842B;'
          default:
          return ''
        }
      },
      closePopup () {
        this.$emit('close-popup')
      },
    }
  }
</script>

<style>
  .popup{
    padding: 70px 25px 31px 30px;
    position: relative;
  }
  .popup__qr__block{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CBCBCB;
  }
  .popup__qr__close {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .popup__qr__wrap {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 25px;
    max-width: 295px;
  }
  .popup__qr__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #9F9F9F;
  }
  .popup__qr__bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }
  .popup__qr {
    width: 100%;
    min-width: 50px;
    max-width: 57px;
    background: #EDEDED;
    border-radius: 25px;
    font-weight: 400;
    font-size: 16px;
    color: #606060;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  .third-markup{
    padding: 30px 25px 31px 30px;
  }
  .popup__close{
    position: absolute;
    top: 25px;
    right: 25px;
  }
  .popup__widget{
    width: 70%;
  }
  .popup__widget__iframe{
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    width: 100%;
    height: 500px;
  }
  .popup__content{
    width: 100%;
    display: flex;
  }

  .popup__static{
    display: flex;
    flex-direction: column;
  }

  .popup__menu{
    padding-left: 25px;
    padding-bottom: 54px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
  }
  .popup__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #333333;
    margin-bottom: 5px;
  }
  .popup__seller{
    font-weight: 400;
    font-size: 16px;
    color: #B1B1B1;
    margin-bottom: 5px;
    line-height: 37px;
  }
  .popup__feedback{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .popup__stars {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }
  .popup__stars span:nth-child(1n) {
    margin-right: 5px;
  }
  .popup__recalls{
    font-weight: 400;
    font-size: 16px;
    text-decoration-line: underline;
    color: #A045C0;
    line-height: 37px;
  }
  .popup__characteristics{
    padding: 10px 20px;
    background: #EDEDED;
    border-radius: 25px;
    font-weight: 400;
    font-size: 16px;
    color: #606060;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    line-height: 37px;
    margin-right: 10px;
    width: 100%;
  }
  .popup__price{
    display: flex;
    align-items: center;
  }
  .popup__price-now{
    font-weight: 700;
    font-size: 32px;
    color: #A045C0;
    margin-right: 17px;
    line-height: 37px;
  }
  .popup__price-old{
    font-weight: 400;
    font-size: 22px;
    line-height: 37px;
    text-decoration-line: line-through;
    color: #C6C6C6;
    margin-bottom: -5px;
  }
  .popup__actions{
    display: flex;
    align-items: center;
  }
  .popup__buy{
    background: #A045C0;
    border-radius: 25px;
    padding: 13px 0px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    letter-spacing: 1px;
    margin-right: 11px;
  }
  .popup__like{
    padding: 13px;
    border-radius: 50%;
    background: #A045C0;
  }
  .second-markup .popup__content{
    flex-direction: row-reverse;
  }
  .second-markup .popup__menu{
    padding-left: 0;
    padding-right: 25px;
  }
  .second-markup .popup__seller{
    position: absolute;
    bottom: 30px;
  }
  .popup__title-third{
    display: none;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #333333;
    margin-bottom: 20px;
  }
  .third-markup .popup__title-third{
    display: block;
  }
  .third-markup .popup__title{
    display: none;
  }

  .third-markup .popup__feedback{
    order: -1;
    margin-bottom: 12px;
  }
  .third-markup .popup__seller{
    margin-bottom: 12px;
  }
  .third-markup .popup__price{
    margin-bottom: 31px;
  }
  .third-markup .popup__like{
    display: none;
  }
</style>