import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import iconValues from './icons';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: iconValues,
    iconfont: 'mdi'
  }, 
});
