<template>
  <div class="catalog__cards">    
    <div class="catalog__cards-title">
      <div class="mr-3">{{ isFullScreen ? "Все товары" : "Все примеры" }}</div>  
      <v-icon size="16" class="mr-3" color="#B9B9B9" v-show="selectedCollection"> mdi-chevron-right</v-icon>
      <div v-if="selectedCollection">{{ selectedCollection.name }}</div>
    </div>

    <div class="catalog__wrap">
      <div v-for="(product, index) in filterProducts" :key="index" class="catalog__cards-item" @click="openProduct(product)">
          <div class="catalog__image">
            <img :src="product.preview ? product.preview : 'https://azmen.a-idea.ru/assets/images/uslugi/sozdaem/avto/sozdanie-3d-modeli-motocikla.jpg'" alt="Превью">
          </div>
          <div class="catalog__subscription">
            {{product.name}}
          </div>
        </div>
    </div>
    <div class="progress__block">
      <v-icon size="100" v-show="loadingProducts">$vuetify.icons.zarboPre</v-icon>
      <!-- <v-progress-circular
        v-show="loadingProducts"
        :size="70"
        :width="7"
        color="#DFD8EE"
        indeterminate
      ></v-progress-circular> -->
    </div>
    <div v-observe-visibility="visibilityChanged"></div>
    <v-dialog
      v-model="dialogProduct"
      :max-width="isFullScreen ? '1546' : '1080'"
      @keydown.esc="dialogProduct = false"
      :key="randomNumbers.markup"
    >
      <v-card>
        <PopupProductVue 
          :product="openedProduct"
          @close-popup="dialogProduct = false"
          :randomNumbers="randomNumbers"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import PopupProductVue from './PopupProductVue'
  export default {
    props: ['products', 'loadingProducts', 'selectedCollection', 'searchResult'],
    components: {
      PopupProductVue
    },
    data() {
      return {
        openedProduct: null,
        dialogProduct: false,
        randomNumbers: {},
      }
    },
    computed: {
      isFullScreen () {
        const isFullScreen = this.$config.FULL_SCREEN
        return isFullScreen
      },
      filterProducts() {
        const products = this.products;
        if (this.searchResult !== '') {
          const searchQuery = this.searchResult.toLowerCase()
          return products.filter(item => {
            const itemName = item.name.toLowerCase()
            const itemGuid = item.guid.toLowerCase()
            const itemDescription = item.description?.toLowerCase()
            return itemName?.includes(searchQuery) || itemGuid?.includes(searchQuery) || itemDescription?.includes(searchQuery)
          });
        }
        return products;
      }
      
    },
    methods: {
      visibilityChanged (isVisible) {
        this.$emit('isVisible', isVisible)
      },
      openProduct (product) {
        this.randomNumbers.markup = this.getRandomInt(3)
        this.randomNumbers.rounded = this.getRandomInt(3)
        this.randomNumbers.color = this.getRandomInt(5)
        this.randomNumbers.recalls = this.getRandomInt(100)
        this.randomNumbers.stars = this.getRandomInt(5)
        this.openedProduct = product
        this.dialogProduct = true
      },
      getRandomInt(max) {
        const min = 1
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min + 1)) + min
      },
      
    }
  }
</script>

<style>

</style>