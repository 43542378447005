import CloseRoundedVue from '@/components/Utils/Icons/CloseRoundedVue';
import StarVue from '@/components/Utils/Icons/StarVue';
import ArrowNavVue from '@/components/Utils/Icons/ArrowNavVue';
import UserVue from '@/components/Utils/Icons/UserVue';
import EmailVue from '@/components/Utils/Icons/EmailVue';
import ZarboPreIconVue from '@/components/Utils/Icons/ZarboPreIconVue.vue';

const iconValues = {
  closeRounded: {component: CloseRoundedVue},
  star: {component: StarVue},
  arrowNav: {component: ArrowNavVue},
  user: {component: UserVue},
  email: {component: EmailVue},
  zarboPre: { component: ZarboPreIconVue }
}

export default iconValues;